import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import settings from '../../settings';

const arrow = `${settings.IMAGES_BASE_URL}/images/assets/scroll-arrow-KO.svg`;
const volumecontrol = `${settings.IMAGES_BASE_URL}/images/assets/bmw-volume.png`;
class HomeVideoBackground extends React.Component {
  state = {
    width: '',
    volume: false
  };
  componentDidMount() {
    this.setState({
      width: window.innerWidth
    });
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  scrollToRef = () => {
    window.scrollTo({
      top: 1000,
      left: 0,
      behavior: 'smooth'
    });
  };


  volumeOn = () => {
    this.setState({
      volume: !this.state.volume
    })
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { width } = this.state;
    const isMobile = width < 767;
    const showHeader = (!isMobile || (isMobile && !this.props.hideMobileHeader));

    return (
      <React.Fragment>
        <div>
        {showHeader ?
          <div className="hero__header video-background-header">
            {this.props.topLeftChildren && (
                <div className="hero__header_topleft">
                  {this.props.topLeftChildren}
                </div>
            )}
          </div> : ""
        }
        {isMobile ? (
          <div>
            {this.props.poster && !this.props.mobileImage && <LazyLoadImage effect="blur" src={this.props.poster} alt="Video Poster" />}
            {this.props.mobileImage && <LazyLoadImage effect="blur" src={this.props.mobileImage} alt="Video Poster" />}
          </div>
        ) : (
          <div className="homevideo-background">
            <video
              id="homevideo-background"
              playsInline="playsinline"
              autoPlay="autoplay"
              muted={this.state.volume ? "" : "muted"}
              loop="loop"
              poster={this.props.poster}
            >
              <source src={this.props.url} type="video/mp4" />
              <track src={this.props.captions} label="English" kind="captions" srcLang="en" default/>
            </video>
            <div className="homevideo-background__content">
              {this.props.image && <LazyLoadImage effect="blur" src={this.props.image} alt={this.props.imageAlt} />}
              <h1 className="homevideo-background__header-text">{this.props.headerTitle}</h1>
              <button className="arrow" aria-label="click here to scroll down" onClick={this.scrollToRef} onKeyDown={this.scrollToRef}>
                <LazyLoadImage effect="blur" src={arrow} alt="arrow" className="scroll-arrow" />
              </button>
              {!this.props.noAudio && <button className="volume__control" onClick={this.volumeOn}><img src={volumecontrol} alt="Volume on" /> </button>}
            </div>
          </div>
        )}
        </div>
      </React.Fragment>
    );
  }
}

export default HomeVideoBackground;